<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <h2 class="titulo-links-escolha" ref="topo">Automatizar Disciplinas</h2>

    <div class="row" style="margin-bottom:20px;">
      <div class="col-md-9"></div>
      <div class="col-md-3">
        <pm-Button type="button"  v-if="acesso.cadastrar" style="float: right;"
          @click="registar()" label="CADASTRAR NOVA AUTOMATIZAÇÃO"
          class="p-button-success"
        >
        </pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable :value="item"   dataKey="id" :rowHover="true" filterDisplay="menu"
         responsiveLayout="scroll">
          <pm-Column field="segmento_nome" sortable header="SEGMENTO" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
          <pm-Column field="serie_nome" sortable header="PERÍODOS" style="font-size: 14px;vertical-align:middle !important">
            <template #body="{data}">
                <div v-for="n in data.serie_nome" :key="n">
                    <div v-if="data.segmento_id == n.segmento_id"> {{ n["nome"] }} </div>
                </div>
            </template>
          </pm-Column>
          <pm-Column field="disciplina_nome" sortable header="DISCIPLINAS" style="font-size: 14px;vertical-align:middle !important">
            <template #body="{data}">
                <div v-for="n in data.disciplina_nome" :key="n">
                    {{ n["nome"] }}
                </div>
            </template>
          </pm-Column>

          <pm-Column field="id" headerStyle="width: 4rem; text-align: center"
             bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
            <template #body="{data}">
              <pm-Button v-if="acesso.editar" icon="pi pi-pencil" title="Editar Disciplinas"
                 class="p-button-raised p-button-rounded mr-2" @click="editar(data.id)"
              />

              <pm-Button v-if="acesso.deletar" icon="pi pi-trash" title="Remover Disciplinas"
                 class="p-button-raised p-button-rounded p-button-danger mr-2" @click="deletar(data.id)"
              />
            </template>
          </pm-Column>

      </pm-DataTable>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Verificar } from "@/class/verificar.js";

export default {
  components: {},
  name: "conf",
  data() {
    const columns = [
      { key: "segmento_nome", label: "Segmento", sortable: true },
      { key: "serie_nome", label: "Periodos", sortable: true },
      { key: "disciplina_nome", label: "Disciplinas", sortable: true },
      { key: "id", label: " " },
    ];
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Automatizar Disciplinas'},
      ],
      item: [],
      columns,
      perPage: 50,
      currentPage: 1,
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      }
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.item.length / this.perPage)
        : this.item.length;
    },
  },
  methods: {
    async registar() {
      this.$router.push({ name: "automatizaradd" });
    },
    async listarTudo() {
      let data = await axios.get("config/padrao/disciplinas");
      this.item = data.data;
    },
    async deletar(id) {
      if (window.confirm("Você realmente deseja remover está configuração de disciplina?")) {
        try{
          let data = await axios.delete('config/padrao/disciplinas/'+ id);
          this.$vaToast.init({
              message: "Removido com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'success'
          });
          this.listarTudo();
          }catch(e){
            let a = e.response.data.erro;
            this.$vaToast.init({
              message: a,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'#e42222'
            })
          }
      }
    },
    async editar(id) {
      this.$router.push({ name: "automatizareditar", params: { id: id }});
    },
  },
  async beforeMount() {
    this.listarTudo();
    let acesso = await Verificar.AcessoLocal(14);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
